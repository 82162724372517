@import "./mixin.scss";
@import "./partials.scss";

.handburgerMenu {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  @include horizontalcenter;
  flex-direction: column;

  .bar {
    width: 20px;
    height: 2px;
    border-radius: 10px;
    background-color: $white;
    transition: 0.5s transform ease;
  }

  .bar:nth-child(1) {
    width: 25px;
    transform: translateY(-5px);
  }

  .bar:nth-child(3) {
    width: 25px;
    transform: translateY(5px);
  }
}

.handburgerActive {
  .bar {
    width: 25px;
    height: 2px;
    background-color: $white;

  }

  .bar:nth-child(1) {
    transform: translateY(2px) rotate(45deg);
  }

  .bar:nth-child(2) {
    scale: 0;
  }

  .bar:nth-child(3) {
    transform: translateY(-2px) rotate(-45deg);
  }
}
