$primary-color: #F84037 ;
$pink:#FDECEC;
$white: #fff;   
$black:#111;

$red:red;
$gray: #828282;
// $background-color:  linear-gradient(90deg, #F84037 70%, #F74A67);
$background-color:#F84037
// $gray: #555;
// $light-gray: #f8f9fa;
// $success-color: #0eec2f;
// $hover: #e98a1d;
// $btn: #c9cde1;
// $blue: #00a1fb;