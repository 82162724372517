@import "./mixin.scss";
@import "./partials.scss";

.gototop {
  position: fixed;
  z-index: 100;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: $primary-color;
  box-shadow: 0px 0px 10px $primary-color;
  @include allCenter;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  overflow: hidden;
  transform: scale(0);
  transition: 0.3s linear ,transform 0.1s ease;

  svg {
    color: $white;
    font-size: 2rem;
    animation: gototop 1s ease infinite;
  }
  @keyframes gototop {
    0% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(0.7);
    }
    100% {
      transform: scale(1.2);
    }
  }
  &:hover{
    background-color: $white;
    box-shadow: 0px 0px 20px $gray;
    svg{
        color: $primary-color
    }
  }
}
.gototopActive{
    transform: scale(1)
}