* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  /* font-family: "Open Sans", sans-serif; */
  /* /* font-family: "Poppins", sans-serif; */
  font-family: "Roboto", sans-serif;
}
a {
  text-decoration: none;
}
.wave {
  position: absolute;
  top: -100px;
  left: 0;
  background: url(./assets/wave.png);
  width: 100%;
  height: 100px;
  background-size: 1000px 100px;
  overflow: hidden;
}

.wave#wave1 {
  z-index: 99;
  opacity: 1;
  bottom: 0;
  animation: animateWave 4s linear infinite;
}

.wave#wave2 {
  z-index: 98;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave_02 4s linear infinite;
}

.wave#wave3 {
  z-index: 100;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWave 3s linear infinite;
}

.wave#wave4 {
  z-index: 98;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave_02 3s linear infinite;
}
@keyframes animateWave {
  0% {
    background-position-x: 1000px;
  }

  100% {
    background-position-x: 0px;
  }
}

@keyframes animateWave_02 {
  0% {
    background-position-x: 0px;
  }

  100% {
    background-position-x: 1000px;
  }
}
.signupModal > div > div {
  width: 500px;
  max-height: 700px;
}
.modal > div > div {
  width: 500px;
  max-height: 500px;
}
.modal_contact {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 20px;
  justify-content: center;
}
.modal_title {
  text-align: center;
  color: #f84037;
  font-size: 1.5rem;
}
.input {
  /* width: 300px; */
  width: 100%;
  display: flex;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input_logo {
  margin-top: 20px;
  margin-right: 10px;
  background-color: #f84037;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input_logo > svg {
  color: white;
}
.input_text {
  width: 100%;
}
.input_title {
  margin-bottom: 5px;
  color: #111;
  font-size: 1.1rem;
}
.input_field > input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  border: 0.5px solid #555;
  font-size: 0.9rem;
  border-radius: 3px;
  padding: 6px 5px;
}
.modal_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_btn > button {
  padding: 10px 20px;
  border-radius: 3px;
  border: none;
  outline: none;
  font-size: 0.9rem;
  background-color: #f84037;
  color: white;
  cursor: pointer;
  width: 60%;
}
.modal_changer {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 4px;
}
.modal_changer > p {
  font-size: 1rem;
}
.modal_changer > p > span {
  color: #f84037;
  cursor: pointer;
}
.navbarLine {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  border-radius: 10px;
}
