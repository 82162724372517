@import "./mixin.scss";
@import "./partials.scss";
.home {
  width: 100%;
  background-color: $pink;

  .home_wrapper {
    max-width: 1400px;
    padding-left: 0;
    padding-right: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    background-color: $pink;
    min-height: 500px;
    max-height: 100%;

    .home_left {
      flex: 1.8;
      padding: 50px 10px 20px 40px;

      h6 {
        margin-bottom: 40px;
        font-size: 0.9rem;
        color: $primary-color;
        margin-left: 10px;
        display: flex;
        align-items: center;
        svg {
          margin-left: 10px;
        }
      }
      h1 {
        font-size: 4.1rem;
        margin-bottom: 50px;
        line-height: 1.2;
        span {
          color: $primary-color;
        }
      }
      p {
        margin-bottom: 70px;
        width: 70%;
      }
      button {
        @include button(10px 20px, 30px);
        font-size: 1.1rem;
      }
    }
    .home_right {
      position: relative;
      flex: 2.2;
      display: flex;
      justify-content: center;
      align-items: end;
      overflow: hidden;
      .home_contact {
        position: absolute;
      }
      .home_image {
        width: 500px;
        height: 500px;
        position: relative;

        img {
          position: absolute;
          left: 0;
          top: 90px;
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
      @media screen and (max-width: 900px) {
        align-items: center;
        .home_image {
          img {
            top: 0;
          }
        }
      }
    }
    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
    }
  }
}
