@import "./mixin.scss";
@import "./partials.scss";

.footer {
  position: relative;
  width: 100%;
  background-color: $primary-color;
  margin-top: 100px;

  .footer_wrapper {
    max-width: 1140px;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow: hidden;
  }
  .footer_item {
    .footer_logo {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      padding: 5px;
      margin-top: 20px;
      margin-bottom: 20px;
      span {
        margin: 0px 12px;
        opacity: 0.9;
        cursor: pointer;
        transition: 0.3s ease;
      }

      //   .footer_logo > span:hover {
      //     transform: translateY(-10px);
      //   }
    }
    ul {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-content: center;
      align-items: center;
      a {
        li {
          position: relative;
          color: white;
          font-size: 1em;
          cursor: pointer;
          font-weight: 500;
          cursor: pointer;
          opacity: 0.9;
          margin: 0px 10px;
        }
      }
    }
  }
}

.footer_item > ul > a > li::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background-color: #fff;
  left: 0;
  bottom: -5px;
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.5s ease-in-out;
}

.footer_item > ul > a > li:hover::before {
  transform: scaleX(1);
  transform-origin: left;
}

.footer_copy {
  position: relative;
  margin-top: 15px;
  margin-bottom: 10px;
  color: #fff;
  display: flex;
  font-size: 0.9em;
  padding: 5px;
}

.footer_copy > p {
  display: flex;
  align-items: flex-end;
}

.footer_copy > img {
  width: 40px;
}
@media screen and (max-width: 620px) {
  .footer_item > ul > a > li {
    font-size: 13px;
  }
  .footer_logo > span > svg {
    width: 26px;
    height: 26px;
  }
  .footer_copy > p {
    font-size: 12px;
  }
  .footer_copy > img {
    width: 32px;
  }
}
