@import "./mixin.scss";
@import "./partials.scss";

.card {
  width: 100%;
  .card_wrapper {
    @include Center;
    max-width: 1400px;
    background-color: $white;
    padding-bottom: 16px;

    .card_title {
      width: 100%;
      @include allCenter;
      text-align: center;
      h1 {
        color: $primary-color;
        font-size: 2em;
        margin-top: 20px;
        margin-bottom: 30px;
      }
    }
    .cards {
      display: flex;
      gap: 30px;
      flex-wrap: wrap;
      justify-content: space-between;

      .itemCard{
        width:400px;
        min-height: 300px;
        max-height: 100%;
        border-radius: 6px;
        overflow: hidden;
        box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.325);
        padding:10px 20px ;
        background-color: $white;
 
        .itmeCard_img{
            position: relative;
            width: 100%;
            height: 200px;
            @include allCenter;
            img{
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .itmeCard_des{
            text-align: center;
            margin-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            align-items: center;
            
            h1{
                font-size: 0.9rem;
                font-weight: 600;
                color: #333;
            }
            button{
              @include button(10px 20px , 6px)
            }

        }
        .itmeCard_btn{
            width: 100%;
            @include allCenter;
            margin: 40px 0px;
            button{
                @include button(8px 16px , 3px)
            }

        }
      }
      &:hover{

      }
    }
  }
}
