@import "./mixin.scss";
@import "./partials.scss";

.MouseMove {
  position: absolute;
  z-index: 100;
  .large_circle {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    pointer-events: none;
    background-color: $primary-color;
    border: 1px solid #fff;
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}
