@import "./mixin.scss";
@import "./partials.scss";

.navbar {
  width: 100%;
  background: $background-color;
  z-index: 999;
  .navbar_wrapper {
    position: relative;
    max-width: 1400px;
    @include Center;
    width: 100%;
    color: $white;
    @include verticallycenter;
    justify-content: space-between;
    padding: 6px 3px;
    white-space: nowrap;
    z-index: 3;
    .navbar_left {
      flex: 1;
      .navbar_logo {
        // background-color: $red;
        width: 160px;
        height: 40px;
        @include allCenter;
        letter-spacing: 3px;
        border-radius: 3px;
        // box-shadow: 0px 0px 5px 1px rgba(255, 255, 255, 0.225);
        h3 {
          font-size: 1.2rem;
          font-weight: 600;
        }
      }
    }
    .navbar_center {
      flex: 2;
      @include allCenter;
      text-align: center;
      ul {
        list-style: none;
        display: flex;
        gap: 50px;
        a {
          position: relative;
          padding: 10px 0px;
          color: $white;
          ::before {
            content: "";
            position: absolute;
            width: 110%;
            height: 3px;
            background-color: $white;
            bottom: -6px;
            border-radius: 5px;
            left: -3px;
            transform: scaleX(0);
            transform-origin: right;
            transition: transform 0.5s ease-in-out;
          }
          &:hover {
            :before {
              transform: scaleX(1);
              transform-origin: left;
            }
          }
        }
      }
      @media screen and (max-width: 800px) {
        background-color: $primary-color;
        position: absolute;
        left: 0;
        top: -500px;
        width: 100%;
        min-height: 100vh;
        max-height: 100%;
        transition: 0.3s;
        z-index: 1;
        ul {
          flex-direction: column;
        }
      }
    }
    .navbar_right {
      flex: 1;
      display: flex;
      justify-content: flex-end;
      gap: 20px;
      .navbar_action {
        @include verticallycenter;
        .loginBtn {
          button {
            @include button(8px 16px, 6px);
            background-color: $white;
            color: $primary-color;
            font-weight: 600;
            letter-spacing: 1px;
            transition: 0.3s;
            &:hover {
              background-color: $primary-color;
              color: $white;
              box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.125);
            }
          }
        }
        .AddtoCart {
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .navbar_responsive {
        display: none;
        margin-left: 15px;

        @media screen and (max-width: 800px) {
          display: block;
        }
      }
    }
  }
}

// Avtive
.navbar_centerActive {
  top: 51px !important;
}
.navbarActive {
  position: relative;
  background: none;
  border: none;
}
.navbarActive::before {
  content: "";
  position: absolute;
  width: 110%;
  height: 3px;
  background-color: $white;
  bottom: -6px;
  border-radius: 5px;
  left: -3px;
}
.navbarSticky {
  position: fixed;
  animation: navbar 0.5s ease;
}
@keyframes navbar {
  0% {
    top: -100px;
  }

  100% {
    top: 0;
  }
}
